import {ag4fruitcounting} from "./data_services/ag4fruitcounting"
export const items = [
    {
      id: "Ag4Air",
      category: "POC",
      title: "Ag4Air",
      description:"Monitoreo de calidad del aire en áreas urbanas y rurales en tiempo real.",
      link:"https://agforest.users.earthengine.app/view/calidad-del-aire",
      pointOfInterest: 80,
      backgroundColor: "#6f4b09", textColor:"#cdcdcd",
    },
    {
      id: "Ag4Asbestos",
      category: "WEB",
      title: "Ag4Asbestos",
      description:"Solución integral para la gestión de todo el ciclo de vida del amianto",
      link:"https://ag4asbestos.agforest.ai/home",
      pointOfInterest: 80,
      backgroundColor: "#a77ecf", textColor:"#cdcdcd",
    },
    {
      id: "Ag4Agro",
      category: "WEB",
      title: "Ag4agro",
      description:"Plataforma de monitoreo agrícola para optimizar cultivos y recursos.",
      link:"https://agroservice.asbestos.ai/main",
      pointOfInterest: 80,
      backgroundColor: "#00c865", textColor:"#070707",
    },
    {
      id: "Ag4Solar",
      category: "WEB",
      title: "Ag4Solar",
      description:"Evaluación del potencial solar para infraestructuras sostenibles.",
      link:"https://ag4solar.agforest.ai/main",
      pointOfInterest: 80,
      backgroundColor: "#25d800", textColor:"#070707",
    },
    {
      id: "Ag4WaterLeaks",
      category: "POC",
      title: "Ag4WaterLeaks",
      description:"Detección de fugas de agua en redes de suministro abastecimiento y saneamiento.",
      link:"https://platform.rezatec.com/signin",
      pointOfInterest: 80,
      backgroundColor: "#7173fa", textColor:"#070707",
    },
    {
      id: "Ag4WaterPollution",
      category: "POC",
      title: "Ag4WaterPollution",
      description:"Análisis de contaminación en cuerpos de agua como ríos y embalses.",
      link:"https://agron-255611.projects.earthengine.app/view/guadalquivirwater",
      pointOfInterest: 80,
      backgroundColor: "#1e118e", textColor:"#cdcdcd",
    },
    {
      id: "Ag4Fire",
      category: "POC",
      title: "Ag4Fire",
      description:"Evaluación de áreas quemadas para la gestión forestal y agrícola.",
      link:"https://pabloquesadamolina.users.earthengine.app/view/burnareas",
      pointOfInterest: 80,
      backgroundColor: "#9e0606", textColor:"#cdcdcd",
    },
    {
      id: "Ag4FruitCounting",
      category: "POC",
      title: "Ag4FruitCounting",
      description:"Conteo automatizado de frutas en árboles para una mejor productividad y evaluación de peritaje.",
      full_description: ag4fruitcounting(),
      link:"http://13.38.170.125/login",
      pointOfInterest: 80,
      backgroundColor: "#868209", textColor:"#cdcdcd",
    },
    {
      id: "asbestos_ai",
      category: "POC",
      title: "Asbestos.ai",
      description:"Gestión avanzada de riesgos de amianto en el sector industrial.",
      link:"https://www.asbestos.ai/",
      pointOfInterest: 80,
      backgroundColor: "#b7a4f7", textColor:"#070707",
    },
    {
      id: "Ag4UHI",
      category: "POC",
      title: "Ag4UHI",
      description:"Monitoreo de islas de calor urbanas para planificación climática.",
      link:"https://agron-255611.projects.earthengine.app/view/uhimadrid",
      pointOfInterest: 80,
      backgroundColor: "#7d7a86", textColor:"#070707",
    },
    {
      id: "Ag4CarbonSinks",
      category: "POC",
      title: "Ag4CarbonSinks",
      description:"Identificación de sumideros de carbono para la captura de CO2 y su potencial comercialización.",
      link:"https://agron-255611.projects.earthengine.app/view/carbonsinks",
      pointOfInterest: 80,
      backgroundColor: "#3f3e40", textColor:"#cdcdcd",
    },
    {
      id: "Ag4Industry",
      category: "POC",
      title: "Ag4Industry",
      description:"Evaluación de impacto ambiental en áreas industriales.",
      link:"https://agforest.users.earthengine.app/view/settlements",
      pointOfInterest: 80,
      backgroundColor: "#3f3e40", textColor:"#cdcdcd",
    },
    {
      id: "Ag4ESDR",
      category: "POC",
      title: "Ag4CSRD",
      description: "Herramienta para la evaluación de riesgo (Scope 3) y sostenibilidad.",
      link:null,
      pointOfInterest: 80,
      backgroundColor: "#3f3e40", textColor:"#cdcdcd",
    },
    {
      id: "ag4eudr",
      category: "POC",
      title: "Ag4EUDR",
      description: "Herramienta de monitorización forestal.",
      link:null,
      pointOfInterest: 80,
      backgroundColor: "#3fae40", textColor:"#cdcdcd",
    },
    

  ];
  
  export const openSpring = { type: "spring", stiffness: 200, damping: 30 };
  