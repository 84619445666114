import React, { createContext, useEffect, useState, useRef } from "react";
import App from "../../App"
const AppContext = createContext({});

export function AppContextProvider() {
  const [baseStyle, setBaseStyle] = useState({ 
    languages: ["ES"], 
    baseColor1: "#000000" 
  })

  
  return (
    <AppContext.Provider
      value={{ 
        baseStyle, 

      }}
    >
      <App />
    </AppContext.Provider>
  );
}

export default AppContext;
