import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { items } from "./data";
import { useNavigate } from "react-router-dom";
import { useSearchParams, createSearchParams } from "react-router-dom";

const getComplementaryColor = (hex) => {
  const r = 255 - parseInt(hex.slice(1, 3), 16);
  const g = 255 - parseInt(hex.slice(3, 5), 16);
  const b = 255 - parseInt(hex.slice(5, 7), 16);
  return `rgb(${r}, ${g}, ${b})`;
};

export function Item({ id }) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query_params = Object.fromEntries([...searchParams]);
  const item = items.find((item) => item.id === id);
  const effectiveLinkColor =
    item.linkColor || getComplementaryColor(item.backgroundColor);
  const effectiveLinkTextColor = item.linkTextColor || item.backgroundColor;

  return (
    <>
      <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, delay: 0.1 }}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: "100vw",
          height: "100vh",
          zIndex: 1,
          pointerEvents: "auto", // Habilita eventos aquí
          backgroundColor: item.backgroundColor+"99",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          navigate({
            pathname: "/main/",
            search: createSearchParams(query_params).toString(),
          });
        }}
      >
        <motion.div
          className="card-content-container open"
          style={{
            
            color: item.textColor,
            zIndex: 3,
            pointerEvents: "auto", // Permite clics en los hijos
            display:"block",
            margin:"auto",
            width:"fit-content",
            height:"fit-content",
            

          }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            // navigate({
            //   pathname: "/main/",
            //   search: createSearchParams(query_params).toString(),
            // });
            
          }}
        >
          <motion.div
            className="card-content"
            layoutId={`card-container-${id}`}
            onClick={(e) => {
              e.stopPropagation(); // Detén la propagación
            }}
          >
            <motion.div
              className="card-image-container"
              layoutId={`card-image-container-${id}`}
              style={{ maxHeight: "min(50vh, 600px)" }}
              onClick={(e) => {
                e.stopPropagation(); // Detén la propagación
              }}
            >
              <img
                className="card-image"
                src={`/images/pocs_images/${id}.png`}
                alt=""
                onClick={(e) => {
                  e.stopPropagation(); // Detén la propagación
                }}
              />
            </motion.div>
            <motion.div
              className="title-container"
              layoutId={`title-container-${id}`}
              style={{
                backgroundColor: "#030303bb",
                borderRadius: "1rem",
                padding: "1rem",
                left: "4rem",
                color: "white", //item.textColor,
              }}
              onClick={(e) => {
                e.stopPropagation(); // Detén la propagación
              }}
            >
              <span className="category">{item.category}</span>
              <h2>{item.title}</h2>
            </motion.div>
              <motion.div
               style={{
                backgroundColor: "#030303bb",
                borderRadius: "1rem",
                padding: "1rem",
                right: "4rem",
                pointerEvents: "auto",
                color: "white", //item.textColor,
              }}
              className="exit-container"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                navigate({
                  pathname: "/main/",
                  search: createSearchParams(query_params).toString(),
                });
              }}>
                X
              </motion.div>
            <motion.div className="content-container open" animate style={{
              pointerEvents: "auto",
              // maxHeight:"min(30vh,600px)",
              overflowY:"auto",
              color:"#dcdcdc",
              height:"100%"
              
              }}>
               <br />
              {item.full_description ? item.full_description : item.description}
            </motion.div>
            {item.link && (
              <button
                style={{
                  display: "flex",
                  width: "60%",
                  
                  zIndex: 15,
                  cursor: "pointer",
                  pointerEvents: "auto",
                  // backgroundColor:"#cdcdcd",
                  backgroundColor: item.backgroundColor+"cc",
                  color:item.textColor
                }}
                className="card-link"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation(); // Detén la propagación
                  window.open(item.link, "_blank");
                }}
              >
               
                <div
                  className="svg"
                >
                  Visitar
                </div>
              </button>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
}
