import React from "react";
import { items } from "./data";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Card({ id, title, category, cardColor, cardTextColor, description, theme }) {
  return (
    <li className={`card `} >
      <div className="card-content-container" >
        <motion.div className="card-content listed" layoutId={`card-container-${id}`} style={{display:"flex", flexDirection:"column-reverse"}}>
          <motion.div
            className="card-image-container "
            layoutId={`card-image-container-${id}`}
          >
            <img className="card-image" src={`/images/pocs_images/${id}.png`} alt="" style={{
               background: cardColor,
               backdropFilter: "blur(5px)"
            }}/>
          </motion.div>
          <motion.div
            className="title-container"
            layoutId={`title-container-${id}`}
            style={{backgroundColor:"#030303aa", borderRadius:"1rem", padding:"1rem", left:"1rem",border:"5px solid "+cardColor+"77"}}
          >
            <span className="category">{category}</span>
            <h2 style={{color:"white"}}>{title}</h2>
          </motion.div>
          <motion.div
            className="desc-container"
            style={{color:cardTextColor, paddingBottom:"2rem", fontWeight:"bold", backgroundColor:cardColor+"bb" }}
            layoutId={`desc-container-${id}`}
          >
          {description}
        </motion.div>
        </motion.div>
        
      </div>
      <Link to={id} className={`card-open-link`} />
    </li>
  );
}

export function List({ selectedId }) {
  return (<>
    <ul className="card-list"  >
      {items.map((card) => (
        <Card id={card.id} key={card.id} cardColor={card.backgroundColor} description={card.description} cardTextColor={card.textColor}  {...card} isSelected={card.id === selectedId} />
      ))}
    </ul>

    </>
  );
}
