import AppContext from "../../Contexts/AppContext";
import React, { useState, useEffect, useRef, useContext } from "react";
import Header from "../../Interfaces/Header/Header";
import { z_index_values } from "../../../services/Commons/zIndexService";
import { isMobile } from "react-device-detect";
import Notiflix from "notiflix";
import { Item } from "./Item";
import { List } from "./List";
import { AnimatePresence } from "framer-motion";
import { useParams } from "react-router";
import "./Main.css";
import Footer from "../../Interfaces/Footer/Footer";
const Main = ({}) => {
  // const windowWidth = useRef(Document.windowWidth);
  // const windowHeight = useRef(Document.windowHeight)//window.innerHeight);
  const {} = useContext(AppContext);
  let params = useParams();
  let id = params.id ? params.id : null;
  const imageHasLoaded = true;

  useEffect(() => {
    id = params.id ? params.id : null;
  }, [params]);
  return (
    <>
      <div
        style={{
          height: "100vh",
          boxSizing: "border-box",
          position: "relative",
          overflow: "hidden",
          backgroundColor:"var(--color-background-light)"
        }}
        className="mainDiv"
      >
        <Header

          showHelpButton={false}
          enableToolbar={true}
          enableSearchBar={true}
        ></Header>
        <div className="list_container">
          <div className="list_container_2">
            <List selectedId={id} />
            <AnimatePresence>
              {id && imageHasLoaded && <Item id={id} key="item" />}
            </AnimatePresence>
          </div>
        </div>
        <Footer />
      </div>
      
    </>
  );
};
export default Main;
