import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import "./Header.css";



export default function Header({
  style = {},
  className = "",
  enableToolbar = false,
  enableSearchBar = false,
}) {
  const [openDrawer, setOpenDrawer]     = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const isSmallScreen                   = useMediaQuery("(max-width:600px)");
  const isVerySmallScreen               = useMediaQuery("(max-width:370px)");

  const handleIconClick = (item) => {
    if (item.component) {
      setSelectedItem(item);
      setOpenDrawer(true);
    } else if (item.action) {
      item.action();
    }
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedItem(null);
  };

  return (
    <>
      <AppBar
        position="static"
        className={`header ${className}`}
        style={{ backgroundColor: "var(--color-primary)",display:"flex",justifyContent:"center",alignItems:"center", ...style }}
      >
        <Toolbar style={{width:"100%",maxWidth:"var(--max-article-width)"}}>
          {!isVerySmallScreen && <a
            href="https://www.agforest.ai"
            className="header_logo"
            target="_blank"
            rel="noreferrer"
          >
             <img
              src="/images/NEW_LOGO.png"
              alt="logo"
              height="70%"
              className="header_logo_img"
            /> 
            {/* <img fetchpriority="high" decoding="async" className="header_logo_img" height="70%" src="https://agforest.ai/wp-content/uploads/2024/07/log0.jpg" alt="" srcset="https://agforest.ai/wp-content/uploads/2024/07/log0.jpg 1357w, https://agforest.ai/wp-content/uploads/2024/07/log0-1280x255.jpg 1280w, https://agforest.ai/wp-content/uploads/2024/07/log0-980x195.jpg 980w, https://agforest.ai/wp-content/uploads/2024/07/log0-480x96.jpg 480w" sizes="(min-width: 0px) and (max-width: 480px) 480px, (min-width: 481px) and (max-width: 980px) 980px, (min-width: 981px) and (max-width: 1280px) 1280px, (min-width: 1281px) 1357px, 100vw" class="wp-image-4319"></img> */}
          </a>}
          
        </Toolbar>
      </AppBar>

    </>
  );
}
