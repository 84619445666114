const ag4fruitcounting = ()=>{
    return <div>
        <div className="header-fruitcounting">
            <h1>Maximiza tu Productividad Agrícola</h1>
            <p>Con tecnología avanzada de conteo automatizado de frutas</p>
        </div>
        <section className="section-fruitcounting ">
            <h2>¿Qué ofrecemos?</h2>
            <p>
                Nuestro sistema de <span class="highlight">conteo automatizado de frutas</span> utiliza un modelo de <span class="highlight">inteligencia artificial</span> de alta precisión, alcanzando más de un <span class="highlight">85% de exactitud</span>. 
                Esto te permite evaluar la productividad de tus árboles de manera eficiente y realizar informes de peritaje confiables.
            </p>
            <h2>¿Cómo funciona?</h2>
            <p>
                Disponible como una <span class="highlight">aplicación Android</span> fácil de usar o como un servicio <span class="highlight">API integrado</span> en tus sistemas actuales. 
                Con solo tomar una fotografía, nuestra tecnología procesa la información y entrega resultados precisos al instante.
            </p>
        </section>
    </div>

}
export {ag4fruitcounting}